import React from 'react';
import { graphql } from 'gatsby';
import '../styles/pages/index.scss';
import Layout from '../components/layout';
import SEO from '../components/seo';
import LogoFader from '../components/logo-fader';

export default ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;
  return (
    <Layout location={location} title={siteTitle}>
      <SEO />
      <div className="home-page">
        <div className="hero">
          <div className="left">
            <h1>Hi, I&apos;m Chris</h1>
            <p>I&apos;m a web developer, designer, and creator of tools.</p>
            <p>
              I make things for me... and for you if you&apos;re interested!
            </p>
          </div>
          <LogoFader />
        </div>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MM D YYYY")
            title
            description
          }
        }
      }
    }
  }
`;
