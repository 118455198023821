import React, { useState, useEffect, useRef } from 'react';
import styles from './logo-fader.module.scss';
import CssLogo from './logos/css3-logo.svg';
import FirebaseLogo from './logos/firebase-logo.svg';
import ReactLogo from './logos/react-logo.svg';
import GoogleCloudLogo from './logos/google-cloud-logo.svg';
import GatsbyLogo from './logos/gatsby-logo.svg';
import GoogleMapsLogo from './logos/google-maps-logo.svg';
import HtmlLogo from './logos/html5-logo.svg';
import JsLogo from './logos/js-logo.svg';
import NodejsLogo from './logos/nodejs-logo.svg';
import PythonLogo from './logos/python-logo.svg';
import RegexLogo from './logos/regex-logo.svg';
import SassLogo from './logos/sass-logo.svg';

export default function LogoFader() {
  const logos = [
    ReactLogo,
    SassLogo,
    CssLogo,
    FirebaseLogo,
    GoogleCloudLogo,
    GatsbyLogo,
    GoogleMapsLogo,
    HtmlLogo,
    JsLogo,
    NodejsLogo,
    PythonLogo,
    RegexLogo
  ];

  const nextLogoIndex = useRef(0);
  const [activeIndex, setActiveIndex] = useState(nextLogoIndex.current);
  const transitionTimeout = useRef();

  const goToNextLogo = () => {
    transitionTimeout.current = setTimeout(() => {
      nextLogoIndex.current = (nextLogoIndex.current + 1) % logos.length;
      setActiveIndex(nextLogoIndex.current);
      goToNextLogo();
    }, 2000);
  };

  useEffect(() => {
    goToNextLogo();

    return () => {
      clearTimeout(transitionTimeout.current);
    };
  }, []);

  return (
    <div className={styles.logo_fader}>
      {logos.map((logo, i) => (
        <img
          key={`logo-${logo}`}
          className={`${styles.logo} ${
            i === activeIndex ? styles.active : styles.inactive
          }`}
          src={logo}
          alt=""
        />
      ))}
    </div>
  );
}
